$primary-color: #2B73FF;
$secondary-color: #FF5733;

.App-title {
    font-size: 7rem;
    margin: 0;
    margin-top: 50px;
    font-weight: 400;
    &--container {
        max-width: 1400px;
        margin: 0 auto;

    }
}
