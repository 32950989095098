$primary-color: #2b73ff;
$secondary-color: #ff5733;

.App-testNet {
  margin-top: 60px;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
