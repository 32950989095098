$primary-color: #2B73FF;
$secondary-color: #FF5733;

.App-header {
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.header-container {
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center; 
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  grid-column: 1; 
  font-size: 1.5rem; 
  font-weight: 500; 
  position: relative;
  padding-left: 50px; 
}

.logo::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 37px; 
  height: 37px; 
  background-image: url("../images/ayetu.svg");
  background-repeat: no-repeat;
  background-size: contain; 
}

nav {
  grid-column: 3; 
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.refresh-button {
  background-image: url("../images/refresh.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
}

.sign-in-button {
  background: $primary-color;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;

  &:hover {
    background: darken($primary-color, 10%);
  }
}
