$primary-color: #3498db;
$background-color: #F7F9FA;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #263746;
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.App {
  background-color: $background-color;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
